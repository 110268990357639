<template>
  <div class="image" :style="style">
    <template v-if="showNewTag">
      <div class="tag-container">
        <div class="tag tag--top-brand" v-if="isNew">
          {{ $t('PRODUCTS.LABELS.NEW') }}!
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { getDefaultThumbnailForProductType } from '@/utils/media';
import moment from '@/services/moment';

export default {
  name: 'ProductThumbnail2',
  props: {
    product: {
      type: Object as () => ProductResponse,
      required: true,
    },
    showNewTag: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    style(): object | null {
      if (this.product.thumbnail_file) {
        const file = this.product.thumbnail_file as PhotoResponse;
        let thumbnailUrl = file.thumbnail;
        if (file.image_url) thumbnailUrl = file.image_url;
        return {
          backgroundImage: 'url(' + thumbnailUrl + ')',
          backgroundSize: 'cover',
        };
      }
      if (this.product.cover_file) {
        const file = this.product.cover_file as PhotoResponse;
        let thumbnailUrl = file.thumbnail;
        if (file.image_url) thumbnailUrl = file.image_url;
        return {
          backgroundImage: 'url(' + thumbnailUrl + ')',
          backgroundSize: 'cover',
        };
      }
      const thumbnailUrl = getDefaultThumbnailForProductType(
        this.product?.product_type
      );
      return {
        backgroundImage: 'url(' + thumbnailUrl + ')',
      };
    },
    isNew() {
      const isNew = moment(this.product.created).isAfter(
        moment().subtract({ days: this.product.media_new_tag_days })
      );
      return isNew;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

$tags-offset: 8px;
$tag-height: 26px;

.image {
  position: relative;
  border-radius: $border-radius;
  background-position: center center;
  background-size: auto 65%;
  background-repeat: no-repeat;
  background-color: var(
    --brand-color-with-opacity-03,
    rgba($light-bubblegum, 0.3)
  );
  object-fit: cover;
  transition: all 0.3s ease-in-out;
}

.tag-container {
  display: flex;
  position: absolute;
  top: $tags-offset;
  left: $tags-offset;
}

.tag {
  color: $white;
  font-size: 3vw;
  z-index: 5;
  position: relative;
  transform: translate(-4vw, -2.5vw);
  text-transform: uppercase;

  @include media-breakpoint-down(sm) {
    font-size: 2rem;
    transform: translate(-1rem, -2rem);
  }
}
</style>
